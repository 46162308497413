import { AppKeyNewgle } from '@pixels/universal/model/apps/app-types';
import { ProjectMeta } from '@pixels/universal/model/apps/project-meta.model';
import { Phase } from '@pixels/universal/model/phase-model';

const FOLDER_NAME = 'newgle-client';
const PROD_WEB_PUSH_KEY = 'BJo3yoiSZnwTapChdtTDW5OBZEARe2CQH6-wAybPNhAUfzTY-W8dXNd8x3GMCScfSvLlisrDZyLudv-hh9fYdg0';
const DEV_WEB_PUSH_KEY = 'BKr25RIRrATLgL2sg0HzU2x9d5Ap080p-dlD0xILXY-FAnsy0uoFkkBLDGMkjCzkCuZ5m2YIivXhnH7IoO0ZQ_4';
const PROD_AUTH_CALLBACK_URL = 'https://pixels-newgle.firebaseapp.com/__/auth/handler';
const DEV_AUTH_CALLBACK_URL = 'https://pixels-newgle-dev.firebaseapp.com/__/auth/handler';
const ProdFirebaseConfig = {
  apiKey: 'AIzaSyCd-gZUNmAjnzlaog1v10LEl5GnF_Mchwg',
  authDomain: 'pixels-newgle.firebaseapp.com',
  projectId: 'pixels-newgle',
  storageBucket: 'pixels-newgle.appspot.com',
  messagingSenderId: '497051797588',
  appId: '1:497051797588:web:6aff2980258e7dc05885c1',
  measurementId: 'G-06Y236D040'
};
const DevFirebaseConfig = {
  apiKey: 'AIzaSyBV_Y2yKXq0sShZDtz3aOLMP2MOmTPqGok',
  authDomain: 'pixels-newgle-dev.firebaseapp.com',
  projectId: 'pixels-newgle-dev',
  storageBucket: 'pixels-newgle-dev.appspot.com',
  messagingSenderId: '336164091550',
  appId: '1:336164091550:web:98309dadc032a68a366d8f',
  measurementId: 'G-JMVN53MXYG'
};
const PROD_TALK_PLUS_APP_ID = '11270666-8a4f-45bc-9375-abeb78d929a1';
const DEV_TALK_PLUS_APP_ID = '618a8b13-f9f9-460e-abea-aaf7728569e4';

export const NewgleProjectMeta: ProjectMeta = {
  appVersion: '1.0.0',
  id: AppKeyNewgle,
  mainPrimaryColor: '#126BFF',
  secondaryColor: '#FF6B00',
  pushBackgroundColor: '#126BFF',
  folderName: FOLDER_NAME,
  publicPath: `dist/apps/${FOLDER_NAME}/browser`,
  supportPhases: [Phase.prod, Phase.dev],
  port: 8215,
  apiName: 'newgle-api',
  apiPort: 5215,
  appId: 'io.pixelteam.newgle',
  appStoreUrl: '',
  appName: '뉴글챗',
  appNameEn: 'Newgle Chat',
  appSchemeName: 'Newgle Chat',
  testTargetUrl: 'https://test-newgle.pixelteam.io',
  devTargetUrl: 'https://dev-newgle.pixelteam.io',
  prodApi: 'https://newgle-api.pixelteam.io',
  devApi: 'https://dev-newgle-api.pixelteam.io',
  firebaseId: 'pixels-newgle',
  testHostname: 'test-newgle',
  prod: {
    deployPath: '/api/v1/project/9070/stage/10103/scenario/12478/deploy',
    targets: ['prod-03'],
    port: 8550,
    androidAppId: '1:497051797588:android:d60330bc3a38e03e5885c1',
    iosAppId: '1:497051797588:ios:48b76dc443f803d05885c1',
    iosEncodingAppId: 'app-1-497051797588-ios-48b76dc443f803d05885c1',
    webAppId: '1:497051797588:web:6aff2980258e7dc05885c1',
    kakaoAppKey: '8a6bc767a88abc58d9797d482e3ad753',
    googleReversedId: 'com.googleusercontent.apps.497051797588-kddc585p2a4mgagjalku2lpsej4ega7d',
    webPushKey: PROD_WEB_PUSH_KEY,
    firebaseConfig: ProdFirebaseConfig,
    talkPlusAppId: PROD_TALK_PLUS_APP_ID,
    apiUrl: 'https://newgle-api.pixelteam.io',
  },
  dev: {
    deployPath: '/api/v1/project/9070/stage/10104/scenario/12477/deploy',
    targets: ['dev-00'],
    port: 8551,
    androidAppId: '1:336164091550:android:17b5b9f7797a77d6366d8f',
    iosAppId: '1:336164091550:ios:ded82eed420e0fce366d8f',
    iosEncodingAppId: 'app-1-336164091550-ios-ded82eed420e0fce366d8f',
    webAppId: '1:213758924700:web:ff0a68981de16f5b855a59',
    kakaoAppKey: '54663c52b09c27be4f2c57b73e669e6c',
    googleReversedId: 'com.googleusercontent.apps.336164091550-t4pf30vt07mqfv766btbnbcgeumsci1e',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://dev-newgle-api.pixelteam.io',
  },
  test: {
    androidAppId: '1:336164091550:android:4163c1f088a8d67b366d8f',
    iosAppId: '1:336164091550:ios:66af9fb5a21ac095366d8f',
    iosEncodingAppId: 'app-1-336164091550-ios-66af9fb5a21ac095366d8f',
    kakaoAppKey: '54663c52b09c27be4f2c57b73e669e6c',
    googleReversedId: 'com.googleusercontent.apps.336164091550-4m96lnccu0rckqlr9la3fcto4an7j1na',
    webPushKey: DEV_WEB_PUSH_KEY,
    firebaseConfig: DevFirebaseConfig,
    talkPlusAppId: DEV_TALK_PLUS_APP_ID,
    apiUrl: 'https://test-newgle-api.pixelteam.io',
  },
  android: {},
  ios: {},
  NSCameraUsageDescription: '카메라로 찍은 사진을 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSPhotoLibraryAddUsageDescription: '회원님이 어게인에 사진을 저장에 대한 액세스 권한을 사용합니다.',
  NSPhotoLibraryUsageDescription: '앨범에 있는 사진과 GIF를 메시지에 첨부 또는 프로필 사진으로 등록할 수 있습니다.',
  NSCameraUsageDescriptionEn: 'You can attach a photo taken with your camera to a message or list it as your profile picture.',
  NSPhotoLibraryAddUsageDescriptionEn: 'You have access to save photos to your account.',
  NSPhotoLibraryUsageDescriptionEn: 'You can use photos and GIFs from your albums as attachments to messages or as your profile picture.',
  consoleLogo: `
NEWGLE`
};

