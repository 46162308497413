import { inject, Injectable, signal } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Platform } from '@ionic/angular/standalone';
import { ONE_ON_ONE_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyNewgle } from '@pixels/universal/model/apps/app-types';
import { NewgleProjectMeta } from '@pixels/universal/model/apps/project-meta/newgle-project-meta';

@Injectable({ providedIn: 'root' })
export class NewgleAppInfo extends AppInfo {
  public readonly appType = AppKeyNewgle;
  public readonly serviceName = _('@뉴글챗@');
  public readonly appStoreUrl = signal(NewgleProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${NewgleProjectMeta.appId}`);

  public override readonly admobBannerVideoChatAppId = (inject(Platform).is('ios') ? NewgleProjectMeta.ios?.admobBannerVideoChatAppId : NewgleProjectMeta.android?.admobBannerVideoChatAppId) ?? '';
  public override readonly loginTitle = _('@뉴글챗@');
  public override readonly loginDescription = _('@돈이_되는_부동산_이야기_n시황부터_청약정보까지_한번에@');
  public override readonly loginAppIconUrl = 'https://cdn.pixelteam.io/pixels/app-icons/newgle-app-icon.png';
  public override readonly bannerPlaceHolder = _('@뉴글챗@');
  public override readonly adminMenus = ONE_ON_ONE_ADMIN_MENUS;
  public override readonly enabledApple: boolean = true;
  public override readonly enabledKakao: boolean = true;
  public override readonly enableNationNumber: boolean = false;
  public override readonly enabledBoardPostsDefaultNation: boolean = false;
  public override readonly enabledOpenChatsPostDefaultNation: boolean = false;
  public override readonly enabledWaitingRoomsDefaultNation: boolean = false;

}
