import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NavController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { alertDialog } from '@pixels/client/dialog/dialog';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { TalkPlusApiService } from '@pixels/client/talkplus/talk-plus-api.service';
import { HChatParamKey } from '@pixels/universal/model/chat/chat-constants';
import { ChatRoomCover } from '@pixels/universal/model/chat/chat-room/schemas/chat-room.schema';
import { catchError, defer, map, Observable, tap } from 'rxjs';

export function chatRoomResolveFn(route: ActivatedRouteSnapshot): Observable<ChatRoomCover | undefined> {
  const homeFullPath = inject(AppInfo).homeFullPath;
  const navCtrl = inject(NavController);
  const t = inject(TranslateService);
  const talkPlusApiService = inject(TalkPlusApiService);
  return defer(() => {
    const chatRoomId = route.params[HChatParamKey.chatRoomId];
    if (!chatRoomId) {
      throw new Error('chatRoomId is not found');
    }
    return talkPlusApiService.fetchOneChatRoomForPublic(chatRoomId);
  }).pipe(
    catchError(err => {
      console.log(err);
      return alertDialog({ content: t.instant(_('@존재하지_않는_방입니다@')) }).pipe(
        map(() => undefined),
        tap(() => navCtrl.navigateRoot(homeFullPath, { replaceUrl: true }))
      );
    }),
  );
}
